import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_b5LO69U7Vr from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-sanctum-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import bootstrap_3sbVBTpf50 from "/opt/atlassian/pipelines/agent/build/plugins/bootstrap.ts";
import confettiExplosion_client_woNANSyQ4V from "/opt/atlassian/pipelines/agent/build/plugins/confettiExplosion.client.ts";
import draggable_hMJ3P6uXQ1 from "/opt/atlassian/pipelines/agent/build/plugins/draggable.ts";
import helpers_mgWmzbOuLB from "/opt/atlassian/pipelines/agent/build/plugins/helpers.ts";
import pusher_client_No5cywskfC from "/opt/atlassian/pipelines/agent/build/plugins/pusher.client.ts";
import vueMultiSelect_FDuXXOWIkv from "/opt/atlassian/pipelines/agent/build/plugins/vueMultiSelect.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_b5LO69U7Vr,
  chunk_reload_client_UciE0i6zes,
  bootstrap_3sbVBTpf50,
  confettiExplosion_client_woNANSyQ4V,
  draggable_hMJ3P6uXQ1,
  helpers_mgWmzbOuLB,
  pusher_client_No5cywskfC,
  vueMultiSelect_FDuXXOWIkv
]