/**
 *  ./plugins/helpers.ts
 */
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default defineNuxtPlugin(() => {
    return {
        provide: {
            formattedDate: (datetime: string) => {
                if(! datetime) {
                    return '-';
                }

                return dayjs(datetime).format('D MMM YYYY');
            },
            relativeDate: (datetime: any) => {
                return dayjs(datetime).fromNow()
            },
            formatValidationErrors: (errors: any) => {
                let errorMessage = '<ul>';
                for (const [key, value] of Object.entries(errors)) {
                    errorMessage += '<li>'+value+'</li>';
                }
                errorMessage += '</ul>';

                return errorMessage;
            },
            initials: (firstName: string, lastName: string) => {
                return firstName[0] + lastName[0]
            },
            uid: () => {
                return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            }
        }
    }
})
