<template>
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
        <div v-for="(toast, index) in toastStore.toasts" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header" :class="'text-bg-'+toast.type">
                <i v-if="toast.icon" class="bi me-2" :class="toast.icon"></i>
                <strong class="me-auto">{{ toast.title }}</strong>
                <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="toastStore.removeToast(index)"></button>
            </div>
            <div v-if="toast.message" class="toast-body" v-html="toast.message">
            </div>
        </div>
    </div>
</template>
  
<script setup lang="ts">
const toastStore = useToastsStore();
</script>
  