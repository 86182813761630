import Echo from 'laravel-echo';
import Pusher from "pusher-js";

export default defineNuxtPlugin((nuxtApp) => {
    const { public: { pusher } } = useRuntimeConfig();
    const { $apiFetch } = useNuxtApp();

    return {
        provide: {
            pusher: () => {
                let options = {
                    authorizer: (channel: string, options: object|null) => {
                        return {
                            authorize: (socketId: string, callback: Function) => {
                                $apiFetch('/broadcasting/auth', {
                                    method: 'post',
                                    body: {
                                        channel_name: channel.name,
                                        socket_id: socketId,
                                    }}).then(response => {
                                        callback(null, response);
                                    }).catch(error => {
                                        callback(true, error);
                                    });
                            }
                        };
                    },
                    broadcaster: 'pusher',
                    cluster: pusher.app_cluster,
                    key: pusher.app_key,
                    enabledTransports: ['ws', 'wss'],
                    // forceTLS: true
                };

                return new Echo({
                    ...options,
                    client: new Pusher(options.key, options)
                });
            }
        }
    };
});
