import { default as indexszjc3LsebsMeta } from "/opt/atlassian/pipelines/agent/build/pages/accounts/[id]/index.vue?macro=true";
import { default as createY89APuyXMGMeta } from "/opt/atlassian/pipelines/agent/build/pages/accounts/create.vue?macro=true";
import { default as indexfERx8Y98uFMeta } from "/opt/atlassian/pipelines/agent/build/pages/accounts/index.vue?macro=true";
import { default as accountswPH1IH9kvKMeta } from "/opt/atlassian/pipelines/agent/build/pages/accounts.vue?macro=true";
import { default as editmpTJY7jLpXMeta } from "/opt/atlassian/pipelines/agent/build/pages/activities/[id]/edit.vue?macro=true";
import { default as indexV5EVeylauJMeta } from "/opt/atlassian/pipelines/agent/build/pages/activities/[id]/index.vue?macro=true";
import { default as createrQvaodUoN9Meta } from "/opt/atlassian/pipelines/agent/build/pages/activities/create.vue?macro=true";
import { default as indexn4nCiHNgZ1Meta } from "/opt/atlassian/pipelines/agent/build/pages/activities/index.vue?macro=true";
import { default as activitiesFa0UsgMuqFMeta } from "/opt/atlassian/pipelines/agent/build/pages/activities.vue?macro=true";
import { default as login103yOntRZlMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth/login.vue?macro=true";
import { default as authszuTmX73lTMeta } from "/opt/atlassian/pipelines/agent/build/pages/auth.vue?macro=true";
import { default as index6tWJT1uSYTMeta } from "/opt/atlassian/pipelines/agent/build/pages/contacts/[id]/index.vue?macro=true";
import { default as contactshp1YG4sx3EMeta } from "/opt/atlassian/pipelines/agent/build/pages/contacts.vue?macro=true";
import { default as externalg4bqR78ab1Meta } from "/opt/atlassian/pipelines/agent/build/pages/external.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as profiley5vrxixVlAMeta } from "/opt/atlassian/pipelines/agent/build/pages/profile.vue?macro=true";
import { default as categoriesMLwR9t1saRMeta } from "/opt/atlassian/pipelines/agent/build/pages/settings/categories.vue?macro=true";
import { default as reportsII5KX8KEJKMeta } from "/opt/atlassian/pipelines/agent/build/pages/settings/reports.vue?macro=true";
import { default as settings88vPJp6eD5Meta } from "/opt/atlassian/pipelines/agent/build/pages/settings.vue?macro=true";
export default [
  {
    name: accountswPH1IH9kvKMeta?.name ?? undefined,
    path: accountswPH1IH9kvKMeta?.path ?? "/accounts",
    meta: accountswPH1IH9kvKMeta || {},
    alias: accountswPH1IH9kvKMeta?.alias || [],
    redirect: accountswPH1IH9kvKMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/accounts.vue").then(m => m.default || m),
    children: [
  {
    name: indexszjc3LsebsMeta?.name ?? "accounts-id",
    path: indexszjc3LsebsMeta?.path ?? ":id()",
    meta: indexszjc3LsebsMeta || {},
    alias: indexszjc3LsebsMeta?.alias || [],
    redirect: indexszjc3LsebsMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/accounts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createY89APuyXMGMeta?.name ?? "accounts-create",
    path: createY89APuyXMGMeta?.path ?? "create",
    meta: createY89APuyXMGMeta || {},
    alias: createY89APuyXMGMeta?.alias || [],
    redirect: createY89APuyXMGMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/accounts/create.vue").then(m => m.default || m)
  },
  {
    name: indexfERx8Y98uFMeta?.name ?? "accounts",
    path: indexfERx8Y98uFMeta?.path ?? "",
    meta: indexfERx8Y98uFMeta || {},
    alias: indexfERx8Y98uFMeta?.alias || [],
    redirect: indexfERx8Y98uFMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/accounts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: activitiesFa0UsgMuqFMeta?.name ?? undefined,
    path: activitiesFa0UsgMuqFMeta?.path ?? "/activities",
    meta: activitiesFa0UsgMuqFMeta || {},
    alias: activitiesFa0UsgMuqFMeta?.alias || [],
    redirect: activitiesFa0UsgMuqFMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/activities.vue").then(m => m.default || m),
    children: [
  {
    name: editmpTJY7jLpXMeta?.name ?? "activities-id-edit",
    path: editmpTJY7jLpXMeta?.path ?? ":id()/edit",
    meta: editmpTJY7jLpXMeta || {},
    alias: editmpTJY7jLpXMeta?.alias || [],
    redirect: editmpTJY7jLpXMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/activities/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexV5EVeylauJMeta?.name ?? "activities-id",
    path: indexV5EVeylauJMeta?.path ?? ":id()",
    meta: indexV5EVeylauJMeta || {},
    alias: indexV5EVeylauJMeta?.alias || [],
    redirect: indexV5EVeylauJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/activities/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createrQvaodUoN9Meta?.name ?? "activities-create",
    path: createrQvaodUoN9Meta?.path ?? "create",
    meta: createrQvaodUoN9Meta || {},
    alias: createrQvaodUoN9Meta?.alias || [],
    redirect: createrQvaodUoN9Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/activities/create.vue").then(m => m.default || m)
  },
  {
    name: indexn4nCiHNgZ1Meta?.name ?? "activities",
    path: indexn4nCiHNgZ1Meta?.path ?? "",
    meta: indexn4nCiHNgZ1Meta || {},
    alias: indexn4nCiHNgZ1Meta?.alias || [],
    redirect: indexn4nCiHNgZ1Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/activities/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: authszuTmX73lTMeta?.name ?? "auth",
    path: authszuTmX73lTMeta?.path ?? "/auth",
    meta: authszuTmX73lTMeta || {},
    alias: authszuTmX73lTMeta?.alias || [],
    redirect: authszuTmX73lTMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: login103yOntRZlMeta?.name ?? "auth-login",
    path: login103yOntRZlMeta?.path ?? "login",
    meta: login103yOntRZlMeta || {},
    alias: login103yOntRZlMeta?.alias || [],
    redirect: login103yOntRZlMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/auth/login.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contactshp1YG4sx3EMeta?.name ?? "contacts",
    path: contactshp1YG4sx3EMeta?.path ?? "/contacts",
    meta: contactshp1YG4sx3EMeta || {},
    alias: contactshp1YG4sx3EMeta?.alias || [],
    redirect: contactshp1YG4sx3EMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contacts.vue").then(m => m.default || m),
    children: [
  {
    name: index6tWJT1uSYTMeta?.name ?? "contacts-id",
    path: index6tWJT1uSYTMeta?.path ?? ":id()",
    meta: index6tWJT1uSYTMeta || {},
    alias: index6tWJT1uSYTMeta?.alias || [],
    redirect: index6tWJT1uSYTMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contacts/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: externalg4bqR78ab1Meta?.name ?? "external",
    path: externalg4bqR78ab1Meta?.path ?? "/external",
    meta: externalg4bqR78ab1Meta || {},
    alias: externalg4bqR78ab1Meta?.alias || [],
    redirect: externalg4bqR78ab1Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/external.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: profiley5vrxixVlAMeta?.name ?? "profile",
    path: profiley5vrxixVlAMeta?.path ?? "/profile",
    meta: profiley5vrxixVlAMeta || {},
    alias: profiley5vrxixVlAMeta?.alias || [],
    redirect: profiley5vrxixVlAMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: settings88vPJp6eD5Meta?.name ?? "settings",
    path: settings88vPJp6eD5Meta?.path ?? "/settings",
    meta: settings88vPJp6eD5Meta || {},
    alias: settings88vPJp6eD5Meta?.alias || [],
    redirect: settings88vPJp6eD5Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: categoriesMLwR9t1saRMeta?.name ?? "settings-categories",
    path: categoriesMLwR9t1saRMeta?.path ?? "categories",
    meta: categoriesMLwR9t1saRMeta || {},
    alias: categoriesMLwR9t1saRMeta?.alias || [],
    redirect: categoriesMLwR9t1saRMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/settings/categories.vue").then(m => m.default || m)
  },
  {
    name: reportsII5KX8KEJKMeta?.name ?? "settings-reports",
    path: reportsII5KX8KEJKMeta?.path ?? "reports",
    meta: reportsII5KX8KEJKMeta || {},
    alias: reportsII5KX8KEJKMeta?.alias || [],
    redirect: reportsII5KX8KEJKMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/settings/reports.vue").then(m => m.default || m)
  }
]
  }
]