/**
 * ./stores/Toast.ts
 */
import { IToast, EToastType } from '~/types/Toast';

export const useToastsStore = defineStore('toasts', () => {
    const toasts = ref<IToast[]>([]);

    const success = (message: string): IToast => {
        return addToast({
            icon: 'fe fe-check-circle',
            message,
            title: 'Success',
            type: EToastType.INFO,
        });

    }

    const info = (message: string): IToast => {
        return addToast({
            icon: 'fe fe-info',
            message,
            title: 'Information',
            type: EToastType.INFO,
        });

    }

    const warning = (message: string): IToast => {
        return addToast({
            icon: 'fe fe-alert-circle',
            message,
            title: 'Information',
            type: EToastType.WARNING,
        });

    }

    const error = (message: string): IToast => {
        return addToast({
            icon: 'fe fe-alert-triangle',
            message,
            title: 'Error',
            type: EToastType.ERROR,
        });
    }

    const validationError = (message: string): IToast => {
        return addToast({
            icon: 'fe fe-alert-triangle',
            message,
            title: 'Validation Error',
            type: EToastType.WARNING,
        });
    }

    const addToast = (toast: IToast): IToast => {
        let newLength = toasts.value.push(toast);
        setTimeout(() => {
            removeToast(newLength - 1);
        }, 5000);

        return toast;
    }

    const removeToast = (index: number): void => {
        if(toasts.value.length === 0) return;
        if(toasts.value.length === 1) {
            toasts.value = [];
            return;
        }
        toasts.value.splice(index, 1);
    }

    return {
        toasts,
        addToast,
        error,
        removeToast,
        success,
        info,
        warning,
        validationError,
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useToastsStore, import.meta.hot));
}
