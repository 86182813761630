/**
 * ./types/Toast.ts
 */

export interface IToast {
    title: string;
    message?: string;
    icon?: string;
    type: EToastType;
}
  
export enum EToastType {
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    PRIMARY = 'primary',
    ERROR = 'danger',
}
  